import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';

{/* <script type="application/javascript">
    if (/MSIE|Trident/.test(window.navigator.userAgent)) {
        alert("Internet Explorer is not supported.\n\nPlease use Google Chrome, Microsoft Edge, Mozilla Firefox, or Safari.");
        window.location.href = 'https://www.google.com/chrome/';
        }
</script> */}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
