import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { frontendUrl, ticketPurchaseUrl } from './constants';
import { useState } from 'react';

export default function NonTicketHolderView() {

  return (
    <Container>
      <Card className="text-center">
        <br />
        <Card.Title className="text-center">
          <h4>
            The show is over...
          </h4>
        </Card.Title>
        <Card.Text>
        We hope you enjoyed it!
        </Card.Text>
        <Card.Text>
        If you have any questions, thoughts to share, or would like to be added to our mailing list please drop us a line at <a href="mailto:directors@embodydanceproject.org" target="_blank">directors@embodydanceproject.org</a>!
        </Card.Text>
        <br />
      </Card>
    </Container>
  )
}