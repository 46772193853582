import React from 'react';
import './App.scss';
import TicketHolderView from './TicketHolderView';
import NonTicketHolderView from './NonTicketHolderView';
import EventFinishedView from './EventFinishedView';
import Header from './Header';
import Footer from './Footer';
import { useState, useEffect } from 'react';

import { backendUrl } from './constants';

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

function App() {

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isVip, setIsVip] = useState(false);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const validationUrl = `${backendUrl}/tickets/${urlParams.get('key')}/validate`;
  //   fetch(validationUrl)
  //   .then(handleErrors)
  //   .then(response => response.json())
  //   .then((response) => {
  //     console.log(response);
  //     setIsAuthenticated(response.isValid);
  //     setIsVip(response.isVip);
  //     // if (response.isValid === 200) {
  //     //   setIsAuthenticated(true);
  //     //   console.log(response.json());
  //     // } else {
  //     //   setIsAuthenticated(false);
  //     // }
  //   })
  //   .catch(() => { return false });
  // }, []);

  return (
    <>
      <Header />
      {/* {isAuthenticated ? <TicketHolderView isVip={isVip} /> : <NonTicketHolderView/>} */}
      <EventFinishedView />
      <Footer />
    </>
  );

}

export default App;
