import React from 'react';
import './App.scss';
import Row from 'react-bootstrap/Row'

export default function Header() {
  return(
    <>
      <div className="d-flex justify-content-center">
        <Row>
          <h1 className="text-center header-link">
            <a href="https://www.embodydanceproject.org/">
              ECHOES
            </a>
          </h1>
        </Row>
      </div>
      <div className="d-flex justify-content-center">
        <Row>
          <h2 className="text-center header-link">
            <a href="https://www.embodydanceproject.org/">
              em(body) dance project
            </a>
          </h2>
        </Row>
      </div>
      <hr className="mt-3 mb-3"/>
      <br />
    </>
  );
}