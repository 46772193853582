import React from 'react';
import './App.scss';
import Row from 'react-bootstrap/Row'

export default function Footer() {
  return(
    <>
      <br />
      <hr className="mt-3 mb-3"/>
      <div className="d-flex justify-content-center">
        <Row>
          <h5 className="text-center">
            <a href="mailto:directors@embodydanceproject.org?subject=ECHOES question" target="_blank">
              Questions, comments, or technical issues? Email us at directors@embodydanceproject.org
            </a>
          </h5>
        </Row>
      </div>
    </>
  );
}